<template>
  <div>
    <Overlay :overlay="overlay"/>

    <v-row align="center" class="pa-0 ma-0 py-1 mx-1">
      <v-col cols="12" lg="3">
        <v-text-field v-model.trim="search"
                      class="rounded-input"
                      clearable
                      dense
                      hide-details
                      outlined
                      placeholder="Recherche..." prepend-inner-icon="mdi-magnify"
                      single-line/>
      </v-col>
      <v-col class="text-end" cols="12" lg="9">
        <v-btn class="mr-2"
               color="primary"
               depressed
               @click="$refs.animatorForm.open(null,'Ajouter un compte animateur')">
          <v-icon left>mdi-plus</v-icon>
          Animateur
        </v-btn>

        <v-btn color="primary" icon @click="$emit('refresh')">
          <v-icon>mdi-table-refresh</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-divider/>

    <v-card-text>

      <v-skeleton-loader v-if="isLoading" type="table"/>

      <v-data-table v-if="animators.length && !isLoading"
                    :headers="headers"
                    :items="animators"
                    :items-per-page="10"
                    :search="search"
                    class="table-border rounded-lg"
      >
        <template v-slot:item.actions="{ item,index }">
          <div class="d-flex">
            <v-btn color="red" disabled icon @click="$emit('delete',[item.id,index])">
              <v-icon size="20">mdi-trash-can</v-icon>
            </v-btn>
            <v-btn icon
                   @click="$refs.animatorForm.open(item,'Modifier un compte animateur')">
              <v-icon size="20">mdi-pencil-outline</v-icon>
            </v-btn>
            <v-btn icon
                   @click="$refs.animatorDetailDialog.open(item)">
              <v-icon size="20">mdi-eye-plus</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon size="20">mdi-chart-pie</v-icon>
            </v-btn>
          </div>
        </template>


        <template v-slot:item.name="{ item }">
          <div class="d-flex text-no-wrap align-center  text-truncate">

            <v-avatar v-if="item.photo" size="38">
              <v-img :src="$baseUrl + item.photo"
                     class="zoom-pointer"
                     @click="$refs.lightbox.open(item.photo)"></v-img>
            </v-avatar>

            <v-avatar v-else
                      :class="$func.randomColor()"
                      class="font-weight-medium"
                      size="38">
              {{ $func.avatarName(item.name) }}
            </v-avatar>

            <span class="ml-2"> {{ item.name }}</span>
          </div>
        </template>


        <template v-slot:item.is_active="{ item }">
          <v-switch v-model="item.is_active"
                    class="pa-0 ma-0"
                    color="secondary"
                    hide-details
                    readonly
                    @click="$emit('change-status',item.id)"/>
        </template>

        <template v-slot:item.status="{ item }">

          <v-chip v-if="item.is_active"
                  class="font-weight-medium bg-success-subtle"
                  small
                  text-color="success">Actif
          </v-chip>

          <v-chip v-else
                  class="font-weight-medium bg-red-subtle"
                  small
                  text-color="red">Inactif
          </v-chip>

        </template>

        <template v-slot:item.gifty_id="{ item }">
          <v-icon v-if="item.gifty_id" color="success">mdi-check-circle</v-icon>
          <v-btn v-else color="primary" text @click="openLinkDialog(item)">
            <v-icon color="primary" left>mdi-vector-link</v-icon>
            Lier
          </v-btn>
        </template>

        <template v-slot:item.email="{  item }">
          <a :href="'mailto:'+item.email"
             :style="{maxWidth: '150px'}"
             class="d-block text-truncate orange--text text-decoration-none">
            {{ item.email }}
          </a>
        </template>

      </v-data-table>

      <NoResult v-if="!isLoading && !animators.length"/>
    </v-card-text>

    <Lightbox ref="lightbox"/>
    <AnimatorForm ref="animatorForm" @refresh="$emit('refresh')"/>
    <AnimatorDetailDialog ref="animatorDetailDialog"/>
    <ConfirmDialog ref="confirmDialog"/>

    <v-dialog v-model="dialog" persistent width="600">
      <v-card>
        <v-card-title>
          Lier le compte avec Gifty
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-8">
          <v-overlay :value="loading"
                     absolute
                     color="white"
                     opacity="0.7">
            <v-progress-circular color="primary"
                                 indeterminate
                                 size="50"
            ></v-progress-circular>
          </v-overlay>

          <form v-if="!Object.keys(user).length" @submit.prevent="getAccountId">


            <v-alert v-if="notFound" border="bottom" class="fs-14"
                     dense dismissible prominent type="error">
              Aucun compte Gifty ne correspond à ce numéro !
            </v-alert>

            <v-text-field v-model="phone"
                          v-number
                          :error-messages="errors.gifty_id"
                          clearable
                          counter="9"
                          outlined
                          persistent-hint
                          placeholder="Entrez le numéro de téléphone sur Gifty.."
                          prefix="+213"
                          prepend-inner-icon="mdi-phone"
                          required
                          type="number"
            ></v-text-field>

            <v-btn :disabled="!phone || phone.length !== 9"
                   :loading="loading"
                   block
                   class="mt-3"
                   color="primary"
                   depressed
                   type="submit">
              <v-icon left>mdi-reload</v-icon>
              Vérifier
            </v-btn>

          </form>

          <div v-else>

            <div class="text-center">
              <v-avatar v-if="user.image" size="100">
                <v-img :src="imageGifty(user.image)"
                       class="grey lighten-4"></v-img>
              </v-avatar>

              <v-avatar v-else
                        :class="$func.randomColor()"
                        class="font-weight-medium avatar-border"
                        size="100">
                     <span class="fs-20">
                       {{ $func.avatarName(user.name) }}
                     </span>
              </v-avatar>
            </div>

            <v-simple-table class="rounded-lg table-border mt-5">
              <template v-slot:default>
                <tbody>
                <tr>
                  <td class="font-weight-medium text-no-wrap py-4" colspan="2">
                    <v-avatar>
                      <GiftyIcon/>
                    </v-avatar>
                    Information du compte GIFTY
                  </td>
                </tr>
                <tr class="grey lighten-4">
                  <td class="font-weight-medium">
                    <v-icon dense left>mdi-account</v-icon>
                    Nom complet
                  </td>
                  <td>{{ user.name }}</td>
                </tr>
                <tr>
                  <td class="font-weight-medium text-no-wrap">
                    <v-icon dense left>mdi-phone</v-icon>
                    N° de téléphone
                  </td>
                  <td>{{ user.phone }}</td>
                </tr>
                <tr class="grey lighten-4">
                  <td class="font-weight-medium text-no-wrap">
                    <v-icon dense left>mdi-calendar-heart</v-icon>
                    Date de naissance
                  </td>
                  <td>{{ user.dob }}</td>
                </tr>
                <tr>
                  <td class="font-weight-medium text-no-wrap">
                    <v-icon dense left>mdi-gender-male-female</v-icon>
                    Sexe
                  </td>
                  <td>{{ user.gender === 'male' ? 'Homme' : 'Femme' }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-btn block
                   class="mt-5"
                   color="secondary"
                   depressed
                   @click="linkAccount">
              <v-icon left>mdi-account-check-outline</v-icon>
              Relier ce compte
            </v-btn>

          </div>

        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import AnimatorForm from "@/views/animators/components/AnimatorForm.vue";
import AnimatorDetailDialog from "@/views/animators/components/AnimatorDetailDialog.vue";
import axios from "axios";
import {HTTP} from "@/http-common";
import GiftyIcon from "@/components/svg-icons/GiftyIcon.vue";

export default {
  components: {GiftyIcon, AnimatorForm, AnimatorDetailDialog},
  props: {
    animators: {
      type: Array,
      default: Array,
    },
    isLoading: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      dialog: false,
      phone: '',
      user: {},
      notFound: false,
      loading: false,
      animator: {},
      errors: {},

      overlay: false,
      search: '',
    }
  },
  methods: {
    openLinkDialog(item) {
      this.animator = item
      this.user = {}
      this.notFound = false
      this.dialog = true
      this.phone = ''
    },
    getAccountId() {
      this.loading = true
      this.notFound = false
      this.user = {}
      let phone = '+213' + this.phone
      axios.get(process.env.VUE_APP_GIFTY_URL + '/api/user/phone/' + phone).then((res) => {
        this.loading = false
        this.user = res.data
        if (!Object.keys(res.data).length) {
          this.notFound = true
        }
      }).catch(err => {
        this.loading = false
        this.$errorMessage = "Une erreur s'est produite, veuillez réessayer !"
        console.log(err)
      })
    },
    imageGifty(image) {
      return process.env.VUE_APP_GIFTY_URL + '/' + image
    },
    linkAccount() {
      this.loading = true
      HTTP.post('/animators/' + this.animator.id + '/store-giftyid', {
        gifty_id: this.user.account.id
      }).then(() => {
        this.loading = false
        this.dialog = false
        this.$successMessage = "Cette opération s'est terminée avec succès !"
        this.$emit('refresh')
      }).catch(err => {
        this.user = {}
        this.loading = false
        this.errors = err.response.data.errors
        console.log(err)
      })
    },
  },
  computed: {
    headers() {
      let _headers = [
        {
          text: 'Statut',
          value: 'is_active',
          show: true
        },
        {
          text: 'Nom',
          value: 'name',
          show: true
        },
        {
          text: 'N°Téléphone',
          value: 'phone',
          show: true
        },
        {
          text: 'Email',
          value: 'email',
          show: true
        },
        // {
        //   text: 'Compte Gifty',
        //   value: 'gifty_id',
        //   show: true,
        //   align: 'center'
        // },
        {
          text: 'Compte',
          value: 'status',
          show: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          show: true
        },
      ]

      return _headers.filter(x => x.show)
    },
  },

}
</script>

<style scoped>

</style>